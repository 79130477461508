<template>
  <div v-if="!loading">
    <div class="receipt-container">
      <v-btn class="export-pdf" @click="exportPDF">Export as PDF</v-btn>
      <div class="header">
        <img src="@/assets/TimeFlyz.png" style="width: 200px; height: 55px" />
        <h2>Booking confirmation</h2>
      </div>
      <div class="content">
        <div>
          <div class="hotel-info">
            <h3>{{ reservation.hotelId.name }}</h3>
            <p class="info-text">
              {{ reservation.hotelId.streetAddress }}, {{ reservation.hotelId.city }},
              {{ reservation.hotelId.state }}, {{ reservation.hotelId.country }},
              {{ reservation.hotelId.postalCode }}
            </p>
          </div>
          <div class="customer-info">
            <h3>Booked By</h3>
            <p class="info-text">
              {{ reservation.bookedBy.firstName }}
              {{ reservation.bookedBy.lastName }}
            </p>
            <p class="info-text">{{ reservation.bookedBy.email }}</p>
            <p class="info-text">
              <i class="cart-type">{{ billingCard.brand }}</i> - ****
              {{ billingCard.last4 }}
            </p>
          </div>
        </div>
        <div class="booking-info">
          <div>
            <h4>CHECK-IN</h4>
            <p>{{ dateTimeFormat(reservation.reservationStart) }}</p>
          </div>
          <div>
            <h4>CHECK-OUT</h4>
            <p>{{ dateTimeFormat(reservation.reservationEnd) }}</p>
          </div>
        </div>
      </div>
      <h2>
        {{ reservation.charge.userVatgst.shortName
        }}{{ parseFloat((+reservation.charge.cost + +reservation.charge.surcharge) * reservation.charge.userExchangeRate).toFixed(2) }} paid on
        {{ dateFormat(reservation.createdAt) }}
      </h2>
      <v-data-table
        :headers="headers"
        :items="['']"
        hide-default-footer
        class="elevation-1 receipt-table"
      >
        <template v-slot:item="row">
          <tr class="item-row">
            <td>
              <p class="table-cell">
                {{ reservation.bookingId }} | Room Rate - {{ reservation.charge.userVatgst.shortName
                }}{{ parseFloat(+reservation.charge.cost * reservation.charge.userExchangeRate).toFixed(2) }}
              </p>
            </td>
            <td>
              <p class="table-cell">1</p>
            </td>
            <td>
              <p class="table-cell">{{ reservation.charge.userVatgst.shortName }}{{ parseFloat(+reservation.charge.cost * reservation.charge.userExchangeRate).toFixed(2) }}</p>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-left">
              <strong>Subtotal</strong>
            </td>
            <td class="text-left">
              <strong
                >{{ reservation.charge.userVatgst.shortName }}{{ parseFloat(+reservation.charge.cost * reservation.charge.userExchangeRate).toFixed(2) }}</strong
              >
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-left">
              <strong>Surcharge</strong>
            </td>
            <td class="text-left">
              <strong
                >{{ reservation.charge.userVatgst.shortName
                }}{{ parseFloat(+reservation.charge.surcharge * reservation.charge.userExchangeRate).toFixed(2) }}</strong
              >
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-left">
              <strong>Amount Paid</strong>
            </td>
            <td class="text-left">
              <strong
                >{{ reservation.charge.userVatgst.shortName
                }}{{ parseFloat((+reservation.charge.cost + +reservation.charge.surcharge) * reservation.charge.userExchangeRate).toFixed(2) }}</strong
              >
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="bg-white" style="margin-top: 20px">
        <strong>Questions?</strong> Contact TimeFlyz at
        <a href="mailto:info@timeflyz.co">info@timeflyz.co</a> or call at
        <a href="tel:+610288306771" class="Blondie">(02) 8830 6771.</a>
      </div>
    </div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      :filename="reservation.bookingId + '-receipt'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="receipt-container">
          <div class="header">
            <img src="@/assets/TimeFlyz.png" style="width: 200px; height: 55px" />
            <h2>Booking confirmation</h2>
          </div>
          <div class="content">
            <div>
              <div class="hotel-info">
                <h3>{{ reservation.hotelId.name }}</h3>
                <p class="info-text">
                  {{ reservation.hotelId.streetAddress }}, {{ reservation.hotelId.city }},
                  {{ reservation.hotelId.state }}, {{ reservation.hotelId.country }},
                  {{ reservation.hotelId.postalCode }}
                </p>
              </div>
              <div class="customer-info">
                <h3>Booked By</h3>
                <p class="info-text">
                  {{ reservation.bookedBy.firstName }}
                  {{ reservation.bookedBy.lastName }}
                </p>
                <p class="info-text">{{ reservation.bookedBy.email }}</p>
                <p class="info-text">
                  <i class="cart-type">{{ billingCard.brand }}</i> - ****
                  {{ billingCard.last4 }}
                </p>
              </div>
            </div>
            <div class="booking-info">
              <div>
                <h4>CHECK-IN</h4>
                <p>{{ dateTimeFormat(reservation.reservationStart) }}</p>
              </div>
              <div>
                <h4>CHECK-OUT</h4>
                <p>{{ dateTimeFormat(reservation.reservationEnd) }}</p>
              </div>
            </div>
          </div>
          <h2>
            {{ reservation.charge.userVatgst.shortName }}{{ parseFloat((+reservation.charge.cost + +reservation.charge.surcharge) * reservation.charge.userExchangeRate).toFixed(2) }} paid on
            {{ dateFormat(reservation.createdAt) }}
          </h2>
          <v-data-table
            :headers="headers"
            :items="['']"
            hide-default-footer
            class="elevation-1 receipt-table"
          >
            <template v-slot:item="row">
              <tr class="item-row">
                <td>
                  <p class="table-cell">
                    {{ reservation.bookingId }} | Room Rate -
                    {{ reservation.charge.userVatgst.shortName }}{{ parseFloat(+reservation.charge.cost * reservation.charge.userExchangeRate).toFixed(2) }}
                  </p>
                </td>
                <td>
                  <p class="table-cell">1</p>
                </td>
                <td>
                  <p class="table-cell">{{ reservation.charge.userVatgst.shortName }}{{ parseFloat(+reservation.charge.cost * reservation.charge.userExchangeRate).toFixed(2) }}</p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="text-left">
                  <strong>Subtotal</strong>
                </td>
                <td class="text-left">
                  <strong
                    >{{ reservation.charge.userVatgst.shortName }}{{ parseFloat(+reservation.charge.cost * reservation.charge.userExchangeRate).toFixed(2) }}</strong
                  >
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="text-left">
                  <strong>Surcharge</strong>
                </td>
                <td class="text-left">
                  <strong
                    >{{ reservation.charge.userVatgst.shortName
                    }}{{ parseFloat(+reservation.charge.surcharge * reservation.charge.userExchangeRate).toFixed(2) }}</strong
                  >
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="text-left">
                  <strong>Amount Paid</strong>
                </td>
                <td class="text-left">
                  <strong
                    >{{ reservation.charge.userVatgst.shortName
                    }}{{ parseFloat((+reservation.charge.cost + +reservation.charge.surcharge) * reservation.charge.userExchangeRate).toFixed(2) }}</strong
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="bg-white" style="margin-top: 20px">
            <strong>Questions?</strong> Contact TimeFlyz at
            <a href="mailto:info@timeflyz.co">info@timeflyz.co</a> or call at
            <a href="tel:+610288306771" class="Blondie">(02) 8830 6771.</a>
          </div>
        </div>
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import VueHtml2pdf from 'vue-html2pdf';

const { getReservation } = require('@/services/receipt/receipt.service');

export default {
  name: 'PDFReceipt',
  data() {
    return {
      loading: true,
      reservation: null,
      billingCard: null,
      reservationID: this.$route.params.reservationID,
      headers: [
        { text: 'Reservation ID/Description', sortable: false },
        { text: 'QTY', sortable: false },
        { text: 'UNIT PRICE', sortable: false },
      ],
    };
  },
  components: {
    VueHtml2pdf,
  },
  created() {
    this.getReceiptDetails();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.mode': function () {
      this.selectedItem = this.$route.params.mode;
    },
  },
  methods: {
    async getReceiptDetails() {
      try {
        this.loading = true;
        const response = await getReservation(this.reservationID);
        this.reservation = response.data.reservation;
        this.billingCard = response.data.cardInfo;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    dateTimeFormat(dateTime) {
      const inputDate = new Date(dateTime);
      return `${inputDate.toLocaleString('en-US', {
        month: 'short',
      })} ${inputDate.getDate()} ${inputDate.getFullYear()} ${inputDate.toLocaleTimeString(
        'en-US',
        { hour: 'numeric', minute: 'numeric' },
      )}`;
    },
    dateFormat(date) {
      const inputDate = new Date(date);
      return `${inputDate.toLocaleString('en-US', {
        month: 'short',
      })} ${inputDate.getDate()} ${inputDate.getFullYear()}`;
    },
    exportPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt-container {
  position: relative;
  max-width: 900px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;

  .export-pdf {
    position: absolute;
    right: 20px;
  }

  .header {
    text-align: center;
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    margin-bottom: 30px;

    .info-text {
      margin-bottom: 4px;
    }

    .cart-type {
      font-weight: 600;
      text-transform: uppercase;
    }

    .hotel-info {
      margin-bottom: 20px;

      h3 {
        margin-bottom: 5px;
      }
    }
  }

  .table-cell {
    font-size: 15px;
    margin-bottom: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
</style>
