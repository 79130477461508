const axios = require('axios');
const constants = require('@/constants');

module.exports = {
  getReservation: id => new Promise((resolve, reject) => {
    axios
      .get(`${constants.API.GET_RECEIPT}/${id}`, {
        headers: {
          Authorization: `Bearer ${window.$cookies.get('token')}`,
        },
      })
      .then(response => resolve(response))
      .catch(err => reject(err));
  }),
};
